// out: ../rate-rise.min.css, sourcemap: true, compress: true, libs: nib
green = #e00000
blue = #e00000
grey = #707070
black = #333333


.rate-rise-timer, .rate-rise-timer *
    font-family: 'Nunito Sans', 'Proxima Nova','Helvetica Neue',Helvetica,Arial,sans-serif;
    line-height: 1


.rate-rise-timer
    font-size 12px
    color black
    position: fixed
    top 130px
    left auto
    right 0
    border-radius: 6px 0 0 6px
    overflow: hidden
    background: #fff
    box-shadow 0 0 10px rgba(0,0,0,.1)
    padding-left: 38px
    transition: 1s ease-in-out
    visibility: hidden
    opacity: 0
    transform translateX(100px)

    &.showTimer
        visibility: visible
        opacity: 1
        transform translateX(0)

    .button-holder
        position: absolute
        left: 0
        top 0
        height: 100%
        width: 38px
        background: green
        text-align: center
        padding-top: 20px

    button
        position:  absolute
        top auto
        bottom 5px
        left 0
        width:  100%
        background: none
        height: 38px
        transition: 1s cubic-bezier(0,1.2,.03,1.25)
        border 0

    .close-timer
        opacity: 0
        transform scale(0)
        z-index: 1

    .expand-timer
        transform rotateY(180deg)
        z-index: 3

    .timer-area
        height: 110px
        display: flex
        align-items: center
        padding 0 10px
        width: 65px
        transition: .5s ease-in-out

    .the-timer
        font-size 28px
        font-weight: 700
        color blue
        display: none
        visibility: hidden
        opacity: 0
        transition:  .5s ease-in-out
        white-space: nowrap

        strong
            line-height: .9
            display: inline-block
            vertical-align: text-top
            padding-top: 6px

            &:after
                content ''
                display: block
                text-align: center
                font-size: 10px
                color grey
                font-weight: normal

            &.timer_days:after
                content 'days'

            &.timer_hrs:after
                content 'hour'

            &.timer_mins:after
                content 'minutes'

            &.timer_secs:after
                content 'seconds'


    &.expanded
        .timer-area
            width: 230px
            padding-left 15px

        .the-timer
            display: block
            visibility: visible
            opacity: 1

        .expand-timer
            display: none

        .close-timer
            transform scale(1)
            opacity: 1
